import { createStore as reduxCreateStore } from "redux"

const reducer = (state, action) => {
  // Update projects state property
  if (action.type === "SET_PROJECTS") {
    if (!action.value) return state

    const projects = action.value
    return Object.assign({}, state, {
      projects,
    })
  }
  // Update payloads state property
  if (action.type === "SET_PAYLOADS") {
    if (!action.value) return state

    const payloads = action.value
    return Object.assign({}, state, {
      payloads,
    })
  }

  // Update selected project state property
  if (action.type === "SET_SELECTED_PROJECT") {
    const project = action.value
    return Object.assign({}, state, {
      selectedProject: project,
    })
  }
  // Update selected project state property
  if (action.type === "SET_SELECTED_PAYLOAD") {
    const payload = action.value

    return Object.assign({}, state, {
      selectedPayload: payload,
    })
  }
    // Update selected project state property
    if (action.type === "SET_SELECTED_TOKEN") {
      const token = action.value
  
      return Object.assign({}, state, {
        selectedToken: token,
      })
    }
  

  // Update shared with me payloads array state property
  if (action.type === "SET_SHARED_WITH_ME") {
    if (!action.value) return state

    const sharedWithMe = action.value
    return Object.assign({}, state, {
      sharedWithMe,
    })
  }

  // Update current view  state property
  if (action.type === "SET_CURRENT_VIEW") {
    const currentView = action.value

    return Object.assign({}, state, {
      currentView,
    })
  }
  // Update current modal  state property
  if (action.type === "SET_CURRENT_MODAL") {
    const currentModal = action.value

    return Object.assign({}, state, {
      currentModal,
    })
  }
  // Set current modal  state property by default
  if (action.type === "HIDE_CURRENT_MODAL") {
    return Object.assign({}, state, {
      currentModal: "",
    })
  }

  // Update user data
  if (action.type === "SET_USER_DATA") {
    const userData = action.value

    return Object.assign({}, state, {
      userData,
    })
  }

  // Set current modal  state property by default
  if (action.type === "SET_DEFAULT_STATE") {
    return initialState
  }
  return state
}

// initial state
const initialState = {
  payloads: [], // All the payloads created by the user
  projects: [], // All the projects created by the user
  selectedPayload: {}, // Payload selected by the user
  selectedProject: {}, // Project selected by the user
  sharedWithMe: [], // Payloads array that are been shared to the user
  currentView: "projects", // 'projects', 'shared with me' , 'my tokens' , 'downloads' , 'project tokens'
  currentModal: "", // 'payload details' , 'new payload', 'new project' , 'chat', 'shared payload details'
  userData : {},
  selectedToken : {}
}

const createStore = () =>
  reduxCreateStore(
    reducer,
    initialState,
    /** Redux DevTools
     *  https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=es
     *
     */
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
export default createStore
