// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-proof-js": () => import("./../../../src/pages/proof.js" /* webpackChunkName: "component---src-pages-proof-js" */),
  "component---src-pages-prototype-js": () => import("./../../../src/pages/prototype.js" /* webpackChunkName: "component---src-pages-prototype-js" */),
  "component---src-pages-showfiles-js": () => import("./../../../src/pages/showfiles.js" /* webpackChunkName: "component---src-pages-showfiles-js" */)
}

